<template>
	<div>
		<div class="box">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="mb-0">{{ $t('invoice.stats_invoices_bons') }}</h3>
                </div>
                <div class="col-auto">
                    <b-button v-b-toggle.invoiceStat variant="primary" class="mb-0">{{ $t('invoice.see_stats') }} <font-awesome-icon :icon="['fal', 'angle-down']" /><font-awesome-icon :icon="['fal', 'angle-up']" /></b-button>
                </div>
            </div>
            <b-collapse v-if="stats_loaded" id="invoiceStat">
                <div class="row pt-4">
                   <b-table striped hover :items="stat" :fields="fields_stat"></b-table>
                </div>
            </b-collapse>
            <div v-else>
                <LoadingSpinner />
            </div>
        </div>
		<div class="row">
		    <div class="col-auto ml-auto d-flex">
		        <a href="" v-if="options.type == 'Contract'" class="btn ml-3 mb-4 btn-secondary" @click.prevent="setupContractInvoices(options.id)">
		            <i><font-awesome-icon :icon="['far', 'file-pdf']" /></i> {{ $t('monte.factures') }} <i><font-awesome-icon :icon="['fas', 'eye']" /></i>
		        </a>
		    </div>
		</div>
		<div class="box">
			<CustomTable
				id_table="bons"
				:items="bons"
				:busy.sync="table_busy"
				primaryKey="invoicedetails_id"
				:hide_if_empty="true"
				:hrefsRoutes="config_table_hrefs"
				:rawColumns="rawColumnNames"
				:externSlotColumns="extern_slot_columns"
				ref="bonTable"
			>
                <template v-slot:[`custom-slot-cell(invoicedetails_tiershorsepartpart)`]="{ data }">
					{{ data.invoicedetails_tiershorsepartpart }}%
				</template>
                <template v-slot:[`custom-slot-cell(invoicedetails_description)`]="{ data }">
					<span v-html="cleanHTML(data.invoicedetails_description)"></span>
				</template>
            </CustomTable>
		</div>

		<template v-if="has_doublons">
			<div class="row">
			    <div class="col">
					<h2>{{ $t("invoice.titre_bons_doublons") }}</h2>
			    </div>
			    <div class="col-auto ml-auto d-flex">
			        <a href="" v-if="options.type == 'Contract'" class="btn ml-3 mb-4 btn-secondary" @click.prevent="setupContractInvoices(options.id)">
			            <i><font-awesome-icon :icon="['far', 'file-pdf']" /></i> {{ $t('monte.factures') }} <i><font-awesome-icon :icon="['fas', 'eye']" /></i>
			        </a>
			    </div>
			</div>
			<div class="box">
				<CustomTable
					id_table="bons_doublons"
					:items="bons_doublons"
					:busy.sync="table_busy"
					primaryKey="invoicedetails_id"
					:hide_if_empty="true"
					:hrefsRoutes="config_table_hrefs"
					:rawColumns="rawColumnNames"
					ref="bonDoublonsTable"
				/>
			</div>
		</template>

		<b-modal ref="modal"  hide-footer>
			<template v-slot:modal-title v-if="!confirmationInvoice0">
				{{ $t("action.facturer") }}
			</template>
			<template v-slot:modal-title v-else>
				{{ $t("action.confirmation") }}
			</template>

			<div v-if="!modal_loaded">
				<LoadingSpinner class="col-12" />
			</div>

			<div v-if="modal_loaded && !confirmationInvoice0" class="row">
				<div class="col">
					<form @submit.prevent="checkIfFirstInvoiceMonth" class="mb-4">
					<div class="row">
						<div class="col-12" v-if="form_message !== ''">
							<ErrorAlert :messageI18n="form_message" />
						</div>

						<div class="col-12">
							

							<div class="row">
								<div class="col-6 mb-2">
									<label class="mb-1">{{ $t('invoice.ref_client') }}</label>
									<input type="text" class="form-control" v-model="ref_client">
								</div>
								<div class="col-6 mb-2">
									<label class="mb-1">{{ $t('invoice.dossier_interne') }}</label>
									<input type="text" class="form-control" v-model="dossier_interne">
								</div>
							</div>

							<div class="form-group">
								<div class="row">
									<div class="col">
										<b-form-checkbox
											v-model="send_email"
											:unchecked-value="false"
										>
										{{ $t('invoice.send_invoice_by_email') }} <font-awesome-icon v-if="isLoadingTemplate" :icon="['fal', 'spinner']" pulse />
										</b-form-checkbox>
									</div>
								</div>

								<div class="row">
									<div class="col">
										<b-form-checkbox
											v-model="many_duedate"
											>
											{{ $t('invoice.multi_echeance') }}
										</b-form-checkbox>
									</div>
								</div>

								<div class="row">
									<div class="col">
										<b-form-checkbox
											v-model="change_model"
											>
											{{ $t('invoice.changer_modele_facture') }} <font-awesome-icon v-if="isLoadingModel" :icon="['fal', 'spinner']" pulse />
										</b-form-checkbox>
									</div>
								</div>
								<div class="row">
									<div class="col">
										<b-form-checkbox
											v-model="change_rib"
											>
											{{ $t('invoice.changer_rib_default') }}
										</b-form-checkbox>
									</div>
								</div>
							</div>
						</div>
					</div>


					<template v-if="change_model_ready">
						<div class="row" v-for="(author, author_key) in authors" :key="'model_' + author_key">
							<div class="col">
								<div class="form-group">
									<label for="">{{ $t('invoice.choisir_un_modele_specifique_pour') }} {{ authors_name[author_key] }}</label>
									<e-select
										v-model="model_choosed[author]"
										id="search_compte"
										track-by="model_label"
										label="model_label"
										:placeholder="getTrad('invoice.choisir_un_modele_specifique')"
										:selectedLabel="getTrad('global.selected_label')"
										:options="model[author]"
										:searchable="true"
										:allow-empty="false"
										:loading="isLoadingModel"
										:show-labels="false"
									>
									</e-select>
								</div>
							</div>
						</div>
					</template>

					<template v-if="send_email_ready">
						<div class="row" v-for="(author, author_key_) in authors" :key="'mail' + author_key_">
							<div class="col">
								<div class="form-group">
									<label for="modeltype_label">{{ $t('invoice.modeltype_label') }} {{ authors_name[author_key_] }}</label>
									<div v-if="tiers_mail[author_key_] && tiers_mail[author_key_] != ''">
										<p>{{ $t('global.destinataires') }}: 
											<span>{{ tiers_mail[author_key_] }}</span> 
										</p>
									</div>
									<e-select
										v-if="show_model_selector"
										v-model="template_choosed[author]"
										id="model_id"
										track-by="model_label"
										label="model_label"
										:placeholder="labelTitleTemplate"
										:deselectLabel="deselectLabel"
										:selectedLabel="selectedLabel"
										:options="templates[author]"
										:searchable="true"
										:allow-empty="false"
										:loading="isLoadingTemplate"
										:show-labels="false"
										@input="setModel(author)"
									>
									</e-select>
									<b-form-checkbox
										v-model="send_mail_sender"
										:unchecked-value="false"
									>
									{{ $t('monte.envoie_mail_sender') }}
									</b-form-checkbox>
								</div>
								<div v-if="template_choosed[author] && template_choosed[author].model_id !== 0">
									<a href="#" @click.prevent="showModel(author)">{{ $t('model.afficher_model') }}</a>
								</div>
							</div>
						</div>
					</template>

					<div class="mb-3" v-for="(invoice, invoice_key) in params_to_send.invoices" :key="'invoice_'+invoice_key">
						<div class="row border-top pt-3">
							<div class="col">
								<label for="duedate_date">{{ invoice.group_by_author_tiers }} {{ $t('invoice.invoice_balance') }} :</label>
								<br>
								<span> {{ amount[invoice.author_id + '_' + invoice.tiers_id].invoice_ttc_formatted }} </span>
							</div>
						</div>
						<div class="row" v-show="many_duedate">
							<div class="col">
								<div class="form-group">
									<template v-for="(duedate, duedate_key) in invoice.duedates" >
										<div class="row" :key="'duedate_'+duedate_key">
											<div class="col">
												<e-datepicker v-model="duedate.date" :class="{ 'is-invalid': errors && errors.indexOf('Erreur date duedate ' + duedate.date) > -1 }"></e-datepicker>
											</div>
											<div class="col">
												<input class="form-control" type="text" v-model="duedate.amount" :class="{ 'is-invalid': errors && errors.indexOf('Erreur somme duedate ' + duedate.amount) > -1 }">
											</div>
										</div>
									</template>
									<a href="#" @click.prevent="add_duedate(invoice)">{{ $t('global.ajouter') }}</a>
								</div>
							</div>
						</div>

						<div class="row" v-if="change_rib_ready">
							<div class="col">
								<div class="form-group">
									<label for="">{{ $t('invoice.choisir_un_rib_specifique') }}</label>
									<e-select
										v-model="invoice.rib"
										id="search_compte"
										track-by="checkdepositconfiguration_id"
										label="checkdepositconfiguration_iban"
										:placeholder="getTrad('invoice.choisir_un_rib_specifique')"
										:selectedLabel="getTrad('global.selected_label')"
										:options="ribs[invoice.author_id]"
										:searchable="true"
										:allow-empty="true"
										:loading="isLoadingRib"
										:show-labels="false"
									>
										<template slot="singleLabel" slot-scope="{ option }">{{ option.checkdepositconfiguration_libelle }} {{ option.checkdepositconfiguration_iban }}</template>
										<template slot="option" slot-scope="{ option }">{{ option.checkdepositconfiguration_libelle }} {{ option.checkdepositconfiguration_iban }}</template>
									</e-select>
								</div>
							</div>
						</div>
						<div class="form-group mt-2">
							<div class="row mx-0">
								<label for="date">{{ $t('invoice.definir_date_facturation') }}</label>
								<small  v-if="last_invoice_date" class="mt-1 ml-2">{{ $t('invoice.date_derniere_facturation') }}: <a href="#" style="color:red;" @click="assignDate(last_invoice_date.invoice_date)">{{ last_invoice_date.formated_date }}</a></small>
							</div>
							<e-datepicker v-model="date"></e-datepicker>
							<div v-if="accounting_closed" class="mt-2 alert alert-danger">{{ $t('invoice.accounting_closed') }} <router-link :to="{ name: 'AccountingInformations', params: { accountingplan_id: accounting_closed }}">{{ $t('support.cliquant_ici') }}</router-link></div>
							<small class="form-text text-muted">{{ $t('invoice.definir_date_facturation_text') }}</small>
						</div>

							<div class="form-group" v-if="date > today && can_display_renew">
								<label for="date_automatique">{{ $t('invoice.renouvellement_facture') }}</label>
								<e-select
									v-model="renew"
									id="code"
									track-by="label"
									label="label"
									:placeholder="labelTitleTemplateRenew"
									:deselectLabel="deselectLabel"
									:selectedLabel="selectedLabel"
									:options="renew_options"
									:searchable="true"
									:allow-empty="false"
									:show-labels="false"
								>
								</e-select>
							</div>
						</div>	
					</form>
				</div>
				<div v-if="show_model" class="col-12 col-lg-6">
					<div v-if="model_selected" class="border-2 px-3 py-3" v-html="model_selected"></div>
					<LoadingSpinner v-else class="col-12" />
				</div>

				<div class="col-12 m-auto">
					<b-button :disabled="accounting_closed !== false" block pill variant="primary" @click="checkIfFirstInvoiceMonth"><font-awesome-icon v-if="processing || processing_check" :icon="['fas', 'spinner']" pulse /> {{ $t("action.ajouter") }}</b-button>
				</div>
			</div>
			<div v-if="modal_loaded && confirmationInvoice0">
				<h3>{{ $t("invoice.ttc_0_confirmation_1") }} {{ this.actual_price_and_currency }}</h3> 
				<h3>{{ $t("invoice.ttc_0_confirmation_2") }}</h3>
				<b-button class="mt-3 rounded-pill" block @click.prevent="continueInvoice0" variant="primary">{{ $t("global.continuer") }}</b-button>
				<b-button class="mt-3 rounded-pill" block @click.prevent="cancelInvoice0" variant="primary">{{ $t("global.annuler") }}</b-button>
			</div>
		</b-modal>

		<b-modal ref="modalLigneVierge" no-close-on-backdrop size="xl" hide-footer>
			<template v-slot:modal-title>

				<template v-if="ajouter">
					{{ $t("action.ajout_ligne_vierge") }}
				</template>

				<template v-if="modifier">
					{{ $t("action.modifier_ligne_vierge") }}
				</template>
			</template>

			<div v-if="!modal_loaded">
				<LoadingSpinner class="col-12" />
			</div>

			<div v-if="modal_loaded">

				<LigneVierge ref="ligne" :invoicedetails_id="invoicedetails_id" :bons="bons" :processing.sync="processing" :preselect_infos="preselect_infos" :ready.sync="ready" :options="options"></LigneVierge>

				<div class="col-8 m-auto">
					<template v-if="ready && invoicedetails_id">
						<b-button block pill variant="primary" @click="onSubmit"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.modifier") }}</b-button>
						<b-button block pill variant="primary" @click="onSubmit(true)"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.modifier_et_nouveau") }}</b-button>
					</template>
					<template v-else-if="ready">
						<b-button block pill variant="primary" @click="onSubmit"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.ajouter") }}</b-button>
						<b-button block pill variant="primary" @click="onSubmit(true)"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.ajouter_et_nouveau") }}</b-button>
					</template>
				</div>
			</div>
		</b-modal>
		<b-modal ref="modalCancel" hide-footer>
			<template v-slot:modal-title>
				{{ $t("modal.invoice.bons.suppression") }}
			</template>

			<p class="text-center">{{ $t("modal.invoice.bons.confirmation_suppression") }}</p>

			<WarnAlert v-if="message_partiel" messageI18n="invoice.baf_partiel_warning" />

			<div class="row">
				<div class="col-12 d-flex justify-content-center mb-2">
					<b-button v-if="message_partiel && is_cancelation" variant="primary" @click="onCancelAll(true)">{{ $t("action.tout_repasser_a_valider") }}</b-button>
					<b-button v-if="message_partiel"  variant="primary" class="mx-2" @click="onCancelAll">{{ $t("action.tout_abandonner") }}</b-button>
				</div>
				<div class="col-12 d-flex justify-content-center">
					<b-button variant="primary" @click="onCancel(true)" v-if="is_cancelation && !message_partiel">{{ $t("action.repasser_a_valider") }}</b-button>
					<b-button variant="primary" class="mx-2" @click="onCancel">{{ $t("action.abandonner") }}</b-button>
					<b-button variant="secondary" @click="$refs.modalCancel.hide()">{{ $t("global.close") }}</b-button>
				</div>
			</div>
		</b-modal>
		<b-modal ref="modalChangeTiers" hide-footer>
			<template v-slot:modal-title>
				{{ $t("action.change_tiers") }}
			</template>

			<div v-if="!modal_loaded">
				<LoadingSpinner class="col-12" />
			</div>

			<div v-if="modal_loaded">

				<form @submit.prevent="checkFormChangeTiers" class="mb-4">
					<div class="row">
						<div class="col-12" v-if="form_message !== ''">
							<ErrorAlert :messageI18n="form_message" />
						</div>

						<div class="col-12">
							<div class="mb-2">
								<label class="mb-1" for="tiers_id">{{ $t('tiers.tiers') }} *</label>
								<e-select
									v-model="change_tiers"
									track-by="id"
									label="rs"
									:placeholder="labelTitleTiers"
									:selectedLabel="selectedLabel"
									:options="tiers"
									:searchable="true"
									:allow-empty="false"
									:loading="isLoadingTiers"
									:show-labels="false"
									:class="{ 'is-invalid': errors && errors.indexOf('Erreur no tiers') > -1 }"
								>
									<template slot="singleLabel" slot-scope="{ option }">{{ option.rs }}</template>
									<template slot="noOptions">{{ $t('global.list_empty') }}</template>
								</e-select>

							</div>
						</div>
					</div>
				</form>

				<div class="col-8 m-auto">
					<b-button v-if="ready && invoicedetails_id" block pill variant="primary" @click="onSubmitChangeTiers"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.modifier") }}</b-button>
				</div>
			</div>
		</b-modal>

		<b-modal ref="confirmation_first_invoice" hide-footer>
			<template v-slot:modal-title>
				{{ $t("invoice.title_first_invoice_of_month") }}
			</template>
			<div class="row justify-content-around">
				<div class="col">
					{{ $t("invoice.first_invoice_of_month") }}
					
					<b-button class="mt-3 rounded-pill" block @click.prevent="checkForm" variant="primary"><font-awesome-icon v-if="processing_check" :icon="['fas', 'spinner']" pulse /> {{ $t("global.continuer") }}</b-button>
					<b-button class="mt-3 rounded-pill" block @click.prevent="closeFirstInvoiceModal" variant="primary">{{ $t("global.annuler") }}</b-button>
				</div>
			</div>
		</b-modal>

		<b-modal size="xl" ref="modelPreview" hide-footer @hidden="base64 = null">
            <template v-slot:modal-title>
                {{ $t("action.previsualiser") }}
            </template>

            <iframe v-if="base64 != null" :src="'data:application/pdf;base64,' + base64" height="1000px" width="100%" style="position:relative"></iframe>
            <div v-else>
                <LoadingSpinner />
            </div>
        </b-modal>

		<b-modal size="xl" ref="modelInvoiceConfirmation0" hide-footer @hidden="base64 = null">
           
        </b-modal>

        <b-modal ref="tiers_vat_modal" hide-footer>
            <template v-slot:modal-title>
                {{ $t('tiers.num_tva') }}
            </template>

            <div class="row justify-content-around">
            	<div class="col-12">
	                <b-alert show variant="warning" class="mt-1">
	                    {{ $t('invoice.message_article_vat_tiers') }}
	                </b-alert>
            	</div>

            	<div class="col-12 mt-2" v-for="(tiers, index) in tiers_vat_selected" :key="tiers.tiers_id">
            		<label>{{ tiers.tiers_rs }}</label>
                    <input type="text" class="form-control" v-model="tiers.tiers_vat_number" @keyup="checkVATFormat(index)" :class="{ 'is-invalid': tiers.error}">
                    <b-alert v-if="!tiers.vat_valid" show variant="warning" class="mt-2">
                        <font-awesome-icon :icon="['fal', 'exclamation-triangle']" style="color: #8F5922;"/>
                        {{ $t('tiers.invalid_format_vat') }}
                    </b-alert>
            	</div>
            	<div class="text-center mt-2">
					<b-button variant="primary" :disabled="!vat_savable" @click="saveVATNumber"><font-awesome-icon v-if="processing_tva" :icon="['fas', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'save']" /> {{ $t("global.enregistrer") }}</b-button>
					<b-button variant="secondary" class="ml-2" @click="addInvoiceForm(invoicedetails_ids_selected)"><font-awesome-icon :icon="['fal', 'long-arrow-right']" /> {{ $t("global.continue_anyway") }}</b-button>
            	</div>
            </div>
        </b-modal>

        <ModalSelectModelPreviewBAF ref="modelSelectPreview" :models="model_by_author" :callback="previewInvoiceDetail" />
        <ModalAvoirCC ref="modalAvoirCC" :submit="submitModalCC" :callback="submitModalCC"/>
	</div>
</template>


<script type="text/javascript">
	import Vue from 'vue'
	import { EventBus } from 'EventBus'

	import Common from '@/assets/js/common.js'
	import TableAction from "@/mixins/TableAction.js"
	import Invoice from "@/mixins/Invoice.js"
	import Tiers from "@/mixins/Tiers.js"
	import Navigation from "@/mixins/Navigation.js"
	import Config from "@/mixins/Config.js"
	import Model from "@/mixins/Model.js"
	import Accounting from "@/mixins/Accounting.js"
	import Contract from "@/mixins/Contract.js"

	import ShutterContract from "@/mixins/shutters-manager/Contract.js"
	import Shutter from '@/mixins/Shutter.js'
	import Tools from '@/mixins/Tools.js'

	import ContractCleaner from '@/assets/js/cache/cleaners/ContractCleaner'
	import _uniq from 'lodash/uniq'
	import _groupBy from 'lodash/groupBy'
	import xss from 'xss'

	export default {
		name: "Bonsv3",
		mixins: [Config, TableAction, Invoice, Navigation, Tiers, ShutterContract, Shutter, Model, Accounting, Contract, Tools],
		props: {
			options: {
				type: Object,
				default: () => ({
					type: 'Invoice',
					id: null,
				})
			}
		},
		data () {
			return {
				send_mail_sender: false,
				bons: null,
				bons_doublons: null,
				table_busy: true,
				first_invoice: false,
				modal_loaded: false,
				invoicedetails_id: null,
				date: new Date(),
				today: new Date(),
				is_cancelation: true,
				processing_check: false,
				send_email: false,
				form_message: '',
				params_to_send : [],
				errors : [],
				config_duedate : [],
				authors : [],
				model_choosed : {},
				template_choosed : {},
				amount : {},
				processing: false,
				ready: false,
				entity_id: null,
				horse_id: null,
				tiers_id: null,
				base64: null,
				ajouter: true,
				modifier: false,
				many_duedate: false,
				has_doublons: false,
				change_model: false,
				isLoadingModel: false,
				isLoadingTemplate: false,
				isLoadingRib: false,
				copy_duedate: null,
				change_rib: false,
				can_display_renew: false,
				send_email_ready: false,
				change_model_ready: false,
				change_rib_ready: false,
				dedoublonner: false,
				cancel_invoicedetails_ids: null,
				preselect_infos: {},
				change_tiers: null,
				use_rib_stallions: true,
				ref_client: null,
				dossier_interne: null,
				show_model: false,
				model_selected: null,
				authors_accounting: {},
				last_invoice_date: {},
				confirmationInvoice0: false,
				actual_price_and_currency: null,
				renew: {
					"code": 'null',
					"label": this.getTrad("invoice.renew.no")
				},
				renew_options: [
					{
						"code": 'null',
						"label": this.getTrad("invoice.renew.no")
					},
					{
						"code": 'mensuel',
						"label": this.getTrad("invoice.renew.mensuel")
					},
					{
						"code": 'annuel',
						"label": this.getTrad("invoice.renew.annuel")
					},
				],
				ribs: [],
				model: [],
				tiers: [],
				isLoadingTiers: false,
				labelTitleTiers: this.getTrad("tiers.rechercher"),
				extern_slot_columns: ['invoicedetails_tiershorsepartpart','invoicedetails_description'],
				selectedLabel: this.getTrad("global.selected_label"),
				labelTitleTemplate: this.getTrad("horse.search_doc"),
				labelTitleTemplateRenew: this.getTrad("invoice.search_renew"),
				deselectLabel: this.getTrad("global.press_enter_to_remove"),
				enter_to_select: this.getTrad("global.press_enter_to_select"),
				cache: {
					model: []
				},
				templates: [],
				template: null,
				recalcul_duedate: true,
				ribs_from_stallions: {},
				new_invoices_tab: [],
				details_selected: [],
				show_model_selector: true,
				rawColumnNames: ['horse.horse_nom','invoicedetails_ht'],
				config_table_hrefs: {
					'horse.horse_nom': {
						routeUniqueName: 'horseFiche',
						routeName: 'horseFiche',
						params: {
							horse_id: 'horse.horse_id'
						}
					},
					'tiers.tiers_rs': {
						routeUniqueName: 'tiersFiche',
						routeName: 'tiersFiche',
						params: {
							tiers_id: 'tiers.tiers_id'
						}
					}
				},
				events_tab: {
					'TableAction::goToAddInvoice': (params) => {
						this.checkVATNumber(params.invoicedetails_id)
					},
					'TableAction::goToAddInvoiceDetail': (params) => {
						this.addInvoiceDetailForm(params.invoicedetails_id)
					},
					'TableAction::goToCancelInvoiceDetail': (params) => {
						this.is_cancelation = true
						this.cancel_invoicedetails_ids = params.invoicedetails_id
						this.dedoublonner = false
						this.checkDetailsGroup();
						this.$refs.modalCancel.show()
						// this.cancelInvoiceDetail(params.invoicedetails_id)
					},
					'TableAction::goToDedoublonnerInvoiceDetail': (params) => {
						this.is_cancelation = false
						this.cancel_invoicedetails_ids = params.invoicedetails_id
						this.dedoublonner = true
						this.$refs.modalCancel.show()
						// this.cancelInvoiceDetail(params.invoicedetails_id, true)
					},
					'TableAction::goToPreviewInvoice': (params) => {
						this.setPreviewInvoiceDetail(params.invoicedetails_id)
					},
					'TableAction::goToPreviewInvoiceWithModel': (params) => {
						this.openModalSelectPreview(params.invoicedetails_id)
					},
					'TableAction::goToChangeTiersBaf': (params) => {
						this.changeTiersBaf(params)
					},
					'TableAction::goToReorganizeInvoiceDetails': (params) => {
						let bons = this.bons.filter(bon => params.indexOf(bon.invoicedetails_id) > -1 )
						let grouped_bons = bons.map((elem) => {
							return elem.invoicedetails_author + "_" + elem.invoicedetails_tiers
						})
						grouped_bons = _uniq(grouped_bons)
						if(grouped_bons.length > 1)
						{
							this.failureToast('toast.reorganize_only_one_tiers')
							return false
						}

						this.$router.push({ name: 'invoiceDetailOrderv2', params: {invoicedetails_ids: params.join(',')}})
					},
					'TableAction::goToCopyInvoiceDetail': (params) => {
						this.copyInvoiceDetail(params)
					},
					'TableAction::goToRecheckVat': (params) => {
						this.recheckVat(params)
					}
				},
				stats_loaded: true,
                stat: [],
                fields_stat: [
					{key: 'tiers', label: this.getTrad('monte.reservation.client'), sortable: true},
                    {key: 'total_ht', label: this.getTrad('invoice.stat_total_ht'), sortable: true},
                ],
                preview_invoicedetails_ids: [],
                model_by_author: [],
                message_partiel: false,
                tiers_vat_selected: [],
                processing_tva: false,
                vat_savable: true,
                invoicedetails_ids_selected: [],
                accounting_closed: false
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
                this.loadBons()
			},
			async loadBons() {
				this.table_busy = true
				this.has_doublons = false
                const bons = await this.loadAllBonsv2(this.options)
                this.bons = await this.formatBons(bons)

                const bons_doublons = await this.loadAllBonsv2({
                	type: 'Doublons',
                	id: null
                })
                this.bons_doublons = await this.formatBons(bons_doublons)
                if(this.bons_doublons.length > 0)
                {
                	this.has_doublons = true
                }

				this.loadStats()
                
                this.table_busy = false
            },
            async formatBons(bons) {
				if(!bons) {
					return []
				}

				for (var i = 0; i < bons.length; i++) {

					if(bons[i].horse != null)
					{
						bons[i].href = "horseFiche"
						bons[i].params = { horse_id: bons[i].horse.horse_id }
					}

					// let dates = []
					let label = bons[i].invoicedetails_label
					// bons[i].invoicedetails_label.split(' - ').forEach(el => {
					// 	if(new Date(el) != 'Invalid Date') {
					// 		dates.push(el)
					// 	}
					// })

					// if(dates.length == 2 && bons[i].invoicedetails_label.split(' - ').length == dates.length) {
					// 	label = dates.join(' - ')
					// }
					// else if(dates.length == 1 && bons[i].invoicedetails_label.split(' - ').length == dates.length) {
					// 	label = dates[0]
					// }

					if(bons[i].invoicedetails_horsepensionperiode != null)
					{
						bons[i].articles.articles_label += " - " + label
					}

					bons[i].group_by_author_tiers = bons[i].author.tiers_rs + " / " + bons[i].tiers.tiers_rs + " (" + this.getTrad("compta.form." + bons[i].vat_code_tiers.toLowerCase()) + ")"
					bons[i].customClasses={
                        invoicedetails_ht:"text-right"
					}

					if(bons[i].invoicedetails_horsepensionperiode) {
						bons[i].group_id = 'pension_'+bons[i].invoicedetails_horsepensionperiode
					}
					else if(bons[i].invoicedetails_actes) {
						bons[i].group_id = 'acte_'+bons[i].invoicedetails_actes
					}
					else {
						bons[i].group_id = 'autre_'+bons[i].invoicedetails_id
					}
				}

				return bons
			},
			async checkVATNumber(invoicedetails_ids) {
				const countries = Object.keys(this.getCountryVatRegex())
				this.invoicedetails_ids_selected = invoicedetails_ids
				const details_selected = this.bons.filter(bons => invoicedetails_ids.includes(bons.invoicedetails_id))

				const details_country = details_selected.filter(details => {
					// si TVA invalide
					if(!details.tiers.tiers_vatcheck && details.tiers.tiers_vat_number)
						return true
					// Si  compta (UE ou UK) ET client  (UE ou UK) ET  pays différents ET une ligne à 0% ET numéro TVA client absent
					if(details.vat.vat_id == 1 && countries.includes(details.author.tiers_country.toString()) && countries.includes(details.tiers.tiers_country.toString()) && details.author.tiers_country !== details.tiers.tiers_country && details.tiers.tiers_vat_number == '')
						return true
					return false
				})
				if(details_country.length == 0) {
					this.addInvoiceForm(invoicedetails_ids)
					return false
				}

				const details_tiers = _groupBy(details_selected, 'invoicedetails_tiers')
				let tiers = []
				for(let i in details_tiers) {
					tiers.push({...details_tiers[i][0].tiers, error: false, vat_valid: this.checkVAT(details_tiers[i][0].tiers.tiers_vat_number, details_tiers[i][0].tiers.tiers_country)})
				}
				this.tiers_vat_selected = tiers
				this.$refs['tiers_vat_modal'].show()

	            this.vat_savable = this.tiers_vat_selected.filter(tiers => !tiers.vat_valid).length == 0

			},
			async checkVATFormat(index) {
	            this.tiers_vat_selected[index].tiers_vat_number = this.tiers_vat_selected[index].tiers_vat_number.replace(/\W/g, '')
	            this.tiers_vat_selected[index].vat_valid = this.checkVAT(this.tiers_vat_selected[index].tiers_vat_number, this.tiers_vat_selected[index].tiers_country)
	            this.tiers_vat_selected[index].error = false

	            this.vat_savable = this.tiers_vat_selected.filter(tiers => !tiers.vat_valid).length == 0
			},
			async saveVATNumber() {
				this.processing_tva = true
				for(let i in this.tiers_vat_selected) {
					if(this.tiers_vat_selected[i].tiers_vat_number) {
						this.tiers_vat_selected[i].error = await this.checkTvaValidOnline(this.tiers_vat_selected[i].tiers_vat_number, this.tiers_vat_selected[i].tiers_country) != 1
						if(!this.tiers_vat_selected[i].error) {
							await this.editVatNumber(this.tiers_vat_selected[i].tiers_id, this.tiers_vat_selected[i].tiers_vat_number, this.tiers_vat_selected[i].tiers_country)
						}
					}
					else {
						await this.editVatNumber(this.tiers_vat_selected[i].tiers_id, this.tiers_vat_selected[i].tiers_vat_number, this.tiers_vat_selected[i].tiers_country)
					}
				}
				let errors = this.tiers_vat_selected.filter(tiers => tiers.error)

				if(errors.length > 0) {
					this.failureToast('toast.vat_invalide')
					this.processing_tva = false
					return false
				}

				this.$sync.force(true)
				this.processing_tva = false

				this.addInvoiceForm(this.invoicedetails_ids_selected)
			},
			async addInvoiceForm(invoicedetails_ids)
			{
				this.$refs['tiers_vat_modal'].hide()
				this.modal_loaded = false
				this.show_model = false
				this.model_selected = false
				this.send_email = false
				this.confirmationInvoice0 = false
				this.$refs['modal'].show()

				this.details_selected = this.bons.filter(bons => invoicedetails_ids.includes(bons.invoicedetails_id))
				this.can_display_renew = false

				this.ref_client = null
				this.dossier_interne = null

				const splited_invoice_details = invoicedetails_ids.split(",")
				const all_bons = this.bons
				const infos = []
                const author_tiers = []
                const tiers_tab = []
                this.authors = []
                this.authors_name = []
                this.tiers_mail = []
                this.model_choosed = {}
                this.template_choosed = {}
				const refs_client = []
				const dossiers_interne = []
				let nb_ligne_vierge = 0
				this.ribs_from_stallions = {}

				this.date = new Date()
				this.renew = {
					"code": 'null',
					"label": this.getTrad("invoice.renew.no")
				}
                
				for (var i = 0; i < splited_invoice_details.length; i++) {
					const bons_selected = this.bons.filter(all_bons => all_bons.invoicedetails_id == splited_invoice_details[i])[0]
					this.last_invoice_date = await this.getLastInvoiceDateByAuthor(bons_selected.author.tiers_id)
					if(this.last_invoice_date && this.last_invoice_date.invoice_date) {
						this.last_invoice_date.invoice_date = new Date(this.last_invoice_date.invoice_date)
						// this.last_invoice_date.formated_date = this.last_invoice_date.invoice_date.toLocaleDateString(Vue.i18n.locale())
						this.last_invoice_date.formated_date = this.formatDate(this.last_invoice_date.invoice_date)
					}

					if(bons_selected.invoicedetails_actes == null && bons_selected.invoicedetails_contract == null && bons_selected.invoicedetails_horsepensionperiode == null) {
						nb_ligne_vierge++
					}

					if(refs_client.indexOf(bons_selected.invoicedetails_ref_client) == -1) {
						refs_client.push(bons_selected.invoicedetails_ref_client)
					}

					if(this.authors.indexOf(bons_selected.invoicedetails_author) == -1) {
						this.authors.push(bons_selected.invoicedetails_author)
						this.authors_name.push(bons_selected.group_by_author_tiers.substring(0, bons_selected.group_by_author_tiers.indexOf("/")))
						let tiers_contact = await this.loadTiersContacts(bons_selected.tiers.tiers_id)
						let tiers_mails = [bons_selected.tiers.tiers_mail]
						// On ne garde que les contacts ayant la fonction comptabilité ET ayant un mail
						tiers_contact = tiers_contact.filter((contact) => (contact.fonctions.some(fonction => fonction.fonction_code == 'COMPTA') && contact.contact.contact_mail !== ""))
						tiers_contact.forEach((contact) => 
							tiers_mails.push(contact.contact.contact_mail)
						)
						this.tiers_mail.push([...new Set(tiers_mails)].join(", ")) // Format d'email sans doublons et séparés par une virgule
					}

					if(dossiers_interne.indexOf(bons_selected.invoicedetails_dossier_interne) == -1) {
						dossiers_interne.push(bons_selected.invoicedetails_dossier_interne)
					}


					if(author_tiers.indexOf(bons_selected.invoicedetails_author + "_" + bons_selected.invoicedetails_tiers) === -1) {
						infos.push({
							"author_id": bons_selected.invoicedetails_author,
							"author_currency": bons_selected.author.tiers_currency,
							"tiers_id": bons_selected.invoicedetails_tiers,
							"group_by_author_tiers": bons_selected.group_by_author_tiers
						})

						tiers_tab.push(bons_selected.tiers)
						author_tiers.push(bons_selected.invoicedetails_author + "_" + bons_selected.invoicedetails_tiers)
					}

					if(bons_selected.contract && bons_selected.contract.contract_config && bons_selected.contract.contract_config.contractconfig_configuration != null) {
						if(!Object.prototype.hasOwnProperty.call(this.ribs_from_stallions, bons_selected.group_by_author_tiers)) {
							this.ribs_from_stallions[bons_selected.group_by_author_tiers] = []
						}

						this.ribs_from_stallions[bons_selected.group_by_author_tiers].push(bons_selected.contract.contract_config.contractconfig_configuration)
					}
					else{
						this.use_rib_stallions = false
					}
				}
				
				if(refs_client.length == 1) {
					this.ref_client = refs_client[0]
				}

				if(dossiers_interne.length == 1) {
					this.dossier_interne = dossiers_interne[0]
				}

				if(nb_ligne_vierge == splited_invoice_details.length) {
					this.can_display_renew = true
				}

				this.params_to_send = {
					"invoices": infos,
					"invoicedetails_ids": invoicedetails_ids
                }
				              
                // On vérifie que les tiers sont notifiables
                const tiers_unotifiable = tiers_tab.filter(tier => {
                    return !tier.tiers_mail
                })

                if(tiers_unotifiable.length === 1) {
                    this.infoToast("invoice.tier_non_notifiable", [tiers_unotifiable[0].tiers_rs])
                }
                else if(tiers_unotifiable.length > 1) {
                    const delimiter = ', '
                    let tiers_rs = tiers_unotifiable.map(tier => tier.tiers_rs).join(delimiter)
                    const index = tiers_rs.lastIndexOf(delimiter)
                    tiers_rs = tiers_rs.substring(0, index) + ' '+this.getTrad('global.et')+' ' + tiers_rs.substring(index + delimiter.length)

                    this.infoToast("invoice.tiers_non_notifiable", [tiers_rs])
                }

                const tiers_ids = tiers_tab.map(tier => tier.tiers_id)
				this.config_duedate = await this.getConfigDuedate(tiers_ids)

				const amount = await this.calculateInvoice(this.params_to_send)
				this.amount = await this.formatAmount(amount)
				let a = Object.keys(this.amount)[0]
				const numeric_ttc = parseFloat(this.amount[a].invoice_ttc.replace(',', '.'))
				const numeric_ht = parseFloat(this.amount[a].invoice_ht.replace(',', '.'))
				if (numeric_ttc === 0 && numeric_ht === 0){
					this.actual_price_and_currency = await this.priceFormat(numeric_ttc, this.params_to_send.invoices[0].author_currency, true)
					this.confirmationInvoice0 = true
				}

				await this.add_duedate()

				this.modal_loaded = true
			},

			continueInvoice0(){
				this.confirmationInvoice0 = false
				return 
			},
			cancelInvoice0(){
				this.$refs['modal'].hide()
				return
			},

			async formatAmount(amount) {
				return amount
			},

			async getNewDate(tiers_id, date) {
				return await this.getNextDateDuedate(tiers_id, date)
			},

			async calculDuedateDate(use_prev = false){

				this.new_invoices_tab = []

				if(this.params_to_send.invoices == undefined) {
					return false
				}

				for (let i = 0; i < this.params_to_send.invoices.length; i++) {
					let author_id = this.params_to_send.invoices[i].author_id
					let tiers_id = this.params_to_send.invoices[i].tiers_id
					let key = author_id + "_" + tiers_id

					let current_date = this.date

					//Je regarde si je dois prendre la date précédente pour calculer les échéances à venir
					if(use_prev)
					{
						if(this.params_to_send.invoices[i].duedates != undefined)
						{
							let all_amount = Math.round(this.amount[key].invoice_ttc.toString().toFloat() * 100)

							//Je parcours les echeances pour calculer la date
							for (let j = 0; j < this.params_to_send.invoices[i].duedates.length; j++)
							{

								let date = null
								let current = null
								let amount_float = 0

								if(j == 0)
								{
									if(this.params_to_send.invoices[i].duedates[j] != undefined)
									{
										current = this.params_to_send.invoices[i].duedates[j].date
									}
									else
									{
										current = this.calculNextDateDuedate(this.config_duedate[tiers_id], current_date)
									}
								}
								else
								{
									if(this.params_to_send.invoices[i].duedates[j].date != undefined)
									{
										current = this.params_to_send.invoices[i].duedates[j].date
									}
									else
									{
										current = this.calculNextDateDuedate(this.config_duedate[tiers_id], this.params_to_send.invoices[i].duedates[j - 1].date)
									}
								}

								//Si j'ai une valeur de renseignée, je la reprend
								if(this.params_to_send.invoices[i].duedates[j].amount != undefined)
								{
									amount_float = Math.round(this.params_to_send.invoices[i].duedates[j].amount.toString().toFloat() * 100)
								}

								if((j + 1) == this.params_to_send.invoices[i].duedates.length)
								{
									amount_float = all_amount
								}

								this.params_to_send.invoices[i].duedates[j] = {
									'date': current,
									'amount': (amount_float / 100).toString().toFloat(),
									'amount_float': amount_float,
									'total_invoice': Math.round(this.amount[key].invoice_ttc.toString().toFloat() * 100)
								}
								all_amount -= amount_float

								if(all_amount < 0)
								{
									all_amount = 0
								}
							}
						}
					}
					else
					{
						let date = this.calculNextDateDuedate(this.config_duedate[tiers_id], current_date)

						let current = new Date(date);
						let amount_float = Math.round(this.amount[key].invoice_ttc.toString().toFloat() * 100)
						this.params_to_send.invoices[i].duedates = [{
							'date': current,
							'amount': this.amount[key].invoice_ttc,
							'amount_float': amount_float,
							'total_invoice': Math.round(this.amount[key].invoice_ttc.toString().toFloat() * 100)
						}]
					}
					this.new_invoices_tab.push(this.params_to_send.invoices[i])
				}

				this.$set(this.params_to_send, 'invoices', this.new_invoices_tab)
			},


			async add_duedate(tab_invoice){
				if(tab_invoice != undefined)
				{
					for (let i = 0; i < this.params_to_send.invoices.length; i++)
					{
						//J'ajoute une date là ou j'ai choisi de cliquer sur ajouter
						if(this.params_to_send.invoices[i].group_by_author_tiers == tab_invoice.group_by_author_tiers)
						{
							let current = new Date(this.date);
							const key = this.params_to_send.invoices[i].author_id + "_" + this.params_to_send.invoices[i].tiers_id

							this.params_to_send.invoices[i].duedates.push({
								'date': undefined,
								'amount': "0",
								'amount_float': 0,
								'total_invoice': Math.round(this.amount[key].invoice_ttc.toString().toFloat() * 100)
							})
						}
					}
					this.calculDuedateDate(true)
				}
				else
				{
					this.calculDuedateDate()
				}

			},

			addInvoiceDetailForm(invoicedetails_id) {
				this.modal_loaded = false
				this.preselect_infos = {}
				if(invoicedetails_id == "")
				{
					this.modifier = false
					this.ajouter = true
				}
				else
				{
					this.modifier = true
					this.ajouter = false
				}
				this.invoicedetails_id = invoicedetails_id

				this.$refs["modalLigneVierge"].show()

				this.modal_loaded = true
			},

			copyInvoiceDetail(invoicedetails) {

				this.modal_loaded = false

				this.modifier = false
				this.ajouter = true
				this.preselect_infos = {
					entity: invoicedetails.author,
					tiers: invoicedetails.tiers,
					horse: invoicedetails.horse,
					article: invoicedetails.articles,
					invoice_details: invoicedetails,
				}

				this.$refs["modalLigneVierge"].show()

				this.modal_loaded = true
			},

			assignDate(date) {
				this.date = date
			},

			async openModalSelectPreview(invoicedetails_ids) {
				this.preview_invoicedetails_ids = invoicedetails_ids
				const ids = this.preview_invoicedetails_ids.split(",")

				const bons_selected = this.bons.filter(bon => ids.includes(bon.invoicedetails_id.toString()))
				const bons_grouped =  _groupBy(bons_selected, 'invoicedetails_author')

				const model_by_author = []
				for (let author in bons_grouped) {
					let accounting_plan = await this.loadAccountingPlanByEntity(author)

					let models = await this.loadAllModel(accounting_plan.accountingplan_id, ['accounting_plan'])
					models = models.filter(model => (model.type.modeltype_template === 'facture'))

					model_by_author.push({
						accounting_plan,
						models
					})
				}
				this.model_by_author = model_by_author

				// si je n'ai qu'une entité sélectionnée et un seul modèle je lance directement la prévisualisation
				// sinon j'ouvre la modale de sélection
				if(this.model_by_author.length == 1 && this.model_by_author[0].models.length == 1) {
					const model_author = {}
					model_author[this.model_by_author[0].accounting_plan.accountingplan_tiers] = this.model_by_author[0].models
					this.previewInvoiceDetail(model_author)
				}
				else {
					this.$refs.modelSelectPreview.openModal()
				}

			},

			setPreviewInvoiceDetail(invoicedetails_ids) {
				this.preview_invoicedetails_ids = invoicedetails_ids
				const ids = this.preview_invoicedetails_ids.split(",")
				this.previewInvoiceDetail()
			},

			async previewInvoiceDetail(models = null) {
				this.modal_loaded = false

				if(models && Object.keys(models).length == 1) {
					this.$refs.modelSelectPreview.closeModal()
				}

				const splited_invoice_details = this.preview_invoicedetails_ids.split(",")
				const all_bons = this.bons
				const infos = []
				const tiers = []
				const authors = []
				for (var i = 0; i < splited_invoice_details.length; i++) {

					const bons_selected = this.bons.filter(all_bons => all_bons.invoicedetails_id == splited_invoice_details[i])[0]

					if(authors.indexOf(bons_selected.invoicedetails_author) === -1 || tiers.indexOf(bons_selected.invoicedetails_tiers) === -1)
					{
						const bon = {
							"author_id": bons_selected.invoicedetails_author,
							"tiers_id": bons_selected.invoicedetails_tiers
						}

						if(models) {
							bon["model"] = {model_id: models[bons_selected.invoicedetails_author].model_id}
						}

						infos.push(bon)

						tiers.push(bons_selected.invoicedetails_tiers)
						authors.push(bons_selected.invoicedetails_author)
					}
				}

				let params_to_send = {
					"invoices": infos,
					"invoicedetails_ids": this.preview_invoicedetails_ids
				}

				let download = true 
				if(infos.length == 1) {
					download = false
					this.$refs.modelPreview.show()
				}

				let res = await this.previewInvoice(params_to_send, download)

				if(infos.length == 1) {
					if(res) {
						this.base64 = res
						EventBus.$emit('TableAction::stopSpin')
					}
				}
				else {
					if(res) {
						EventBus.$emit('TableAction::stopSpin')
					}
				}

				this.$refs.modelSelectPreview.closeModal()

				this.modal_loaded = true
			},

			checkIfFirstInvoiceMonth: async function() {
				this.processing_check = true
				const elems = await this.checkFirstInvoiceMonth(this.date.toDateInputValue(), this.params_to_send.invoicedetails_ids)
				
				this.processing_check = false
				if(elems.nb == 0) {
					this.$refs['confirmation_first_invoice'].show()
				}
				else {
					await this.checkForm()
				}
			},

			closeFirstInvoiceModal: async function(){
				this.$refs['confirmation_first_invoice'].hide()
			},

			checkForm: async function(){
				
				if(!this.processing)
				{
					this.processing = true
					this.errors = []

					if(this.date == null)
					{
						this.errors.push("Erreur date null")
					}

					if(!Date.parse(this.date))
					{
						this.errors.push("Erreur wrong date")
					}

					//Je vais regarder que mes dates d'échéances soient supérieurs ou égales à ma date de facturation, et regarder le montant
					for (let i = 0; i < this.params_to_send.invoices.length; i++)
					{
						let somme = 0

						if(this.change_model && this.model_choosed[this.params_to_send.invoices[i].author_id] == undefined) {
							this.errors.push("Erreur model")
						}

						if(this.change_rib && (this.params_to_send.invoices[i].rib == undefined || this.params_to_send.invoices[i].rib == null)) {
							this.errors.push("Erreur compte bancaire (rib)")
						}

						//Si j'ai qu'un seul rib etalon de selectionné pour une entité/tiers, et que je n'ai pas choisi manuellement mon rib
						if(this.ribs_from_stallions[this.params_to_send.invoices[i].group_by_author_tiers] != undefined
							&& _uniq(this.ribs_from_stallions[this.params_to_send.invoices[i].group_by_author_tiers]).length == 1
							&& (this.params_to_send.invoices[i].rib == undefined || this.params_to_send.invoices[i].rib == null)
							&& this.use_rib_stallions
						) {
							this.params_to_send.invoices[i].rib = {
								"checkdepositconfiguration_id": this.ribs_from_stallions[this.params_to_send.invoices[i].group_by_author_tiers][0]
							}
						}

						if(this.model_choosed[this.params_to_send.invoices[i].author_id] != undefined) {
							this.params_to_send.invoices[i].model = this.model_choosed[this.params_to_send.invoices[i].author_id]
						}
						
						if(this.template_choosed[this.params_to_send.invoices[i].author_id] != undefined) {
							this.params_to_send.invoices[i].template = this.template_choosed[this.params_to_send.invoices[i].author_id]
						}

						for (let j = 0; j < this.params_to_send.invoices[i].duedates.length; j++)
						{
							if(j == 0)
							{
								somme = this.params_to_send.invoices[i].duedates[j].total_invoice
							}
							if(!Date.parse(this.params_to_send.invoices[i].duedates[j].date) || this.params_to_send.invoices[i].duedates[j].date < this.date)
							{
								this.errors.push("Erreur date duedate " + this.params_to_send.invoices[i].duedates[j].date)
							}

							somme -= Math.round(this.params_to_send.invoices[i].duedates[j].amount.toString().toFloat() * 100)
						}
						if(somme != 0)
						{
							this.errors.push("Erreur somme duedate " + this.params_to_send.invoices[i].duedates[0].amount)
						}
                    }

					if(this.errors.length == 0)
					{
						this.params_to_send.date = this.date;
						this.params_to_send.send_mail = this.send_email;
						this.params_to_send.ref_client = this.ref_client
						this.params_to_send.dossier_interne = this.dossier_interne
						this.params_to_send.send_mail_sender = this.send_mail_sender;


						let renew = null
						if(this.date > this.today) {
							if(this.renew.code != 'null') {
								renew = this.renew.code
							}
						}

						this.params_to_send.renew = renew
						this.params_to_send.ref_client = this.ref_client
						this.params_to_send.dossier_interne = this.dossier_interne

						const result = await this.createInvoice(this.params_to_send);
						if(result) {
							const details_contract = this.details_selected.filter(details => details.invoicedetails_contract)
							const contract_ids = _uniq(details_contract.map(details => details.invoicedetails_contract))
		                    this.$sync.force(true)
		                	ContractCleaner.inst().onMutation(contract_ids, ['articles'])
		                	this.updateContract(contract_ids)
							this.$refs['modal'].hide()
							this.closeFirstInvoiceModal()

							if(result.retour.length == 1 && result.retour[0].invoice_ttc > 0) {
								this.$refs['modalAvoirCC'].init_component(result.retour[0].invoice_tiers, result.retour[0].tiers.tiers_rs, result.retour[0].invoice_accountingplan)
								this.loadBons()
								this.$refs.bonTable.unselectAll()
							}
							else {
								this.$router.push({name: 'invoiceList'})
							}
						} else {
							this.form_message = "error.LEP"
						}
					}
					else
					{
						this.closeFirstInvoiceModal()
						this.form_message = "formulaire.erreur_champs_non_remplis"
					}

					this.processing = false
				}

			},

			submitModalCC() {
				this.$router.push({name: 'invoiceList'})
			},

			async onCancel(go_valider) {
				this.$refs.modalCancel.hide();
				if(go_valider === true) {
					await this.cancelInvoiceDetail(this.cancel_invoicedetails_ids, this.dedoublonner, true)
				}
				else {
					await this.cancelInvoiceDetail(this.cancel_invoicedetails_ids, this.dedoublonner, false)
				}
			},

			async onCancelAll(go_valider) {
				this.$refs.modalCancel.hide();

				let details_cancel = this.cancel_invoicedetails_ids.split(',')
				let details = this.bons.filter(b => details_cancel.includes(b.invoicedetails_id.toString()))
				let group = details.map(d => d.group_id)

				// je récupère les lignes appartenant aux groupes de mes lignes sélectionnées
				for(let i in group) {
					if(group[i].includes('pension_') || group[i].includes('acte_')) {
						let details_code = this.bons.filter(b => group[i] == b.group_id && !details_cancel.includes(b.invoicedetails_id.toString()))
						details_code = details_code.map(d => d.invoicedetails_id.toString())
						details_code.forEach(d => {
							details_cancel.push(d)
						})
					}
				}

				if(go_valider === true) {
					await this.cancelInvoiceDetail(details_cancel.join(','), this.dedoublonner, true)
				}
				else {
					await this.cancelInvoiceDetail(details_cancel.join(','), this.dedoublonner, false)
				}
			},

			async cancelInvoiceDetail(invoicedetails_ids, dedoublonner, send_a_valider){
				let params_to_send = {
					invoicedetails_ids: invoicedetails_ids,
					send_a_valider: send_a_valider
				}

				let result = null
				if(dedoublonner != undefined && dedoublonner === true) {
					result = await this.dedoublonnerInvoiceDetails(params_to_send)
				}
				else{
					result = await this.deleteInvoiceDetails(params_to_send)
				}


				if(result != null) {
					this.$refs.bonTable.resetCachedItems()

					if(this.$refs.bonDoublonsTable) {
						this.$refs.bonDoublonsTable.resetCachedItems()
					}

					this.successToast()
					this.loadBons()

					if(this.options.type == "Contract"){
						//Je force la synchro pour reload la table contract_avenant_article et ensuite je vais recharger les "a valider"
						EventBus.$emit('TableAction::waitingTableAValider', true)
						await this.$sync.force(true, true)
	                    ContractCleaner.inst().onMutation([this.options.id], ['articles'])
	                    this.updateContract([this.options.id])
	                    EventBus.$emit('TableAvenantArticle::ReloadArticle', true)
						EventBus.$emit('TableAction::reloadAValider', true)
					}
				}
			},

			async recheckVat(params){
				let result = await this.getRecheckVat(params.invoicedetails_id.split(','))

				if(result != null) {
					this.$refs.bonTable.resetCachedItems()
					this.successToast()
					this.loadBons()
				}
			},

			async onSubmit(open_with_copy = false) {
				this.processing = true
				let invoice_details = await this.$refs.ligne.checkForm()

				if(invoice_details !== false)
				{
					this.$refs["modalLigneVierge"].hide()
					this.successToast()
					this.loadBons()
					if(this.options.type == "Contract") {
                    	await this.$sync.force(true)
	                	ContractCleaner.inst().onMutation([this.options.id], ['articles'])
	                	this.updateContract([this.options.id])
					}

					if(open_with_copy === true) {
						this.invoicedetails_id = null
						//Dans le cas d'ajout de ligne, on me retourne un tableau de ligne, du coup je prend la 1ere
						if(Array.isArray(invoice_details.retour)) {
							this.copyInvoiceDetail(invoice_details.retour[0])
						}
						else {
							this.copyInvoiceDetail(invoice_details.retour)
						}
					}
				}
				this.processing = false
				this.ready = true
			},
			reset_copy_duedate(){

				this.copy_duedate = null
			},

			pension_checkall(item){
				if(item.length)
				{
					if(item[0].invoicedetails_horsepensionperiode != null)
					{
						//Je vais regarder toutes les pensions id qui vont ensembles sur le même cheval et même tiers pour toutes les facturer en même temps
						let invoicedetails_horsepensionperiode = item[0].invoicedetails_horsepensionperiode
						
						let all_same_pensions = this.bons.filter(pension => pension.invoicedetails_horsepensionperiode == invoicedetails_horsepensionperiode)

						this.$refs.bonTable.manual_check(all_same_pensions)
					}
				}
				
			},

			pension_decheckall(item){
				if(item.length)
				{
					if(item[0].invoicedetails_horsepensionperiode != null)
					{
						let invoicedetails_horsepensionperiode = item[0].invoicedetails_horsepensionperiode

						let all_same_pensions = this.bons.filter(pension => pension.invoicedetails_horsepensionperiode == invoicedetails_horsepensionperiode) 
						this.$refs.bonTable.manual_decheck(all_same_pensions)
					}
				}
				
			},

			async load_model(){

				this.change_model_ready = false
				this.isLoadingModel = true
				for (let i = 0; i < this.authors.length; i++) {
					let result = await this.loadAccountingPlanByEntity(this.authors[i])

					let models = await this.loadAllModel(result.accountingplan_id, ['accounting_plan'])
					models = models.filter(model => (model.type.modeltype_template === 'facture'))

					this.model[this.authors[i]] = models
				}

				this.change_model_ready = true
				this.isLoadingModel = false
			},

			async load_model_notification(){

				this.send_email_ready = false
				this.isLoadingTemplate = true
				for (let i = 0; i < this.authors.length; i++) {
					let result = await this.loadAccountingPlanByEntity(this.authors[i])
					this.authors_accounting[this.authors[i]] = result.accountingplan_id
					const templates = await this.loadAllModel(result.accountingplan_id, ['accounting_plan'], 'invoice_notification')

					const model_default = {
						model_default: false,
						model_id: 0,
						model_label: this.getTrad('invoice.model_mail_default'),
					}

					templates.push(model_default)
					this.templates[this.authors[i]] = templates
					this.template_choosed[this.authors[i]] = model_default
				}

				this.send_email_ready = true
				this.isLoadingTemplate = false
			},

			async load_rib(){
				this.change_rib_ready = false
				for (let i = 0; i < this.params_to_send.invoices.length; i++) {
					let result = await this.loadAccountingPlanByEntity(this.params_to_send.invoices[i].author_id)

					let ribs = await this.getCheckDepositConfigListe(result.accountingplan_id)

					this.ribs[this.params_to_send.invoices[i].author_id] = ribs
				}

				this.change_rib_ready = true
			},

			async changeTiersBaf(invoicedetails_ids){
				this.$refs.modalChangeTiers.show()
				this.ready = false
				this.modal_loaded = false
				this.isLoadingTiers = true
				this.invoicedetails_id = invoicedetails_ids

				let authors = []
				for (var i = 0; i < this.bons.length; i++) {
					if(invoicedetails_ids.includes(this.bons[i].invoicedetails_id)) {
						authors.push(this.bons[i].invoicedetails_author)
					}
				}

				let tiers = await this.loadTiers()
				this.tiers = []
                for (let i = 0; i < tiers.length; i++) {
					if(!authors.includes(tiers[i].tiers_id)) {
						this.tiers.push({
							id: tiers[i].tiers_id,
							rs: tiers[i].tiers_formatted,
						})
					}
				}

				this.ready = true
				this.modal_loaded = true
				this.isLoadingTiers = false
			},

			async onSubmitChangeTiers(){

				if(!this.processing)
				{
					this.processing = true
					this.errors = []
					if(!this.invoicedetails_id)
					{
						this.errors.push("lignes")
					}

					if(!this.change_tiers)
					{
						this.errors.push("Erreur no tiers")
					}
					if(this.errors.length > 0)
					{
						this.processing = false
						return false
					}

					let result = null
					result = await this.setChangeTiers(this.invoicedetails_id, this.change_tiers.id)
					if(result != null)
					{
						this.successToast();
						this.$refs.modalChangeTiers.hide()
						this.processing = false
						
						await this.loadBons();
					}
					else{
						this.processing = false
						this.failureToast();
					}
					this.change_tiers = null
				}

			},

			async loadStats() {
                this.stats_loaded = false
                this.stat = []

                if(this.bons.length === 0) {
                    this.stats_loaded = true
                    return false
                }

				const invoicedetails_ids = this.bons.map(invoice_details => invoice_details.invoicedetails_id)

                const stats = await this.loadInvoiceBonsStat(invoicedetails_ids)

                await Common.asyncForEach(stats, async (stat) => {
                    this.stat.push({
                        tiers: stat.tiers,
                        total_ht: await this.priceFormat(stat.total_ht, 'EUR')
                    })
                })

                this.stats_loaded = true
            },

            async setModel(author) {
            	this.show_model_selector = false
            	this.$nextTick(function () {
            		this.show_model_selector = true
            	})
            	this.model_selected = false
        		if(this.template_choosed[author] && this.template_choosed[author].model_id) {
        			const models = await this.loadModel(this.authors_accounting[author], this.template_choosed[author].model_id, 'accounting_plan')
					const templates = await this.loadTemplate(this.authors_accounting[author], this.template_choosed[author].model_id, 'accounting_plan',  Vue.i18n.locale())
					const zone = models[0].zone.find(zone => zone.zonetype.modelzonetype_code == 'invoice_notification_body')
					this.model_selected = templates.find(temp => temp.modeltemplate_modelzone == zone.modelzone_id).modeltemplate_content
        		}
            },
			showModel(author) {
				this.setModel(author)
				this.show_model = true
				let modal = document.getElementsByClassName('modal-dialog')[0]
				modal.classList.add('modal-xxl')
			},
			checkDetailsGroup() {
				// je récupère les lignes de ma sélection
				const details_cancel = this.cancel_invoicedetails_ids.split(',')
				let details = this.bons.filter(b => details_cancel.includes(b.invoicedetails_id.toString()))
				let group = {}
				details.forEach(detail => {
					if(!Object.prototype.hasOwnProperty.call(group, detail.group_id)) {
						group[detail.group_id] = 0
					}
					group[detail.group_id]++
				})
				// je vérifie si dans ma sélection je n'ai pas des lignes partielles (ex: pension)
				this.message_partiel = false
				for(let code in group) {
					let details_code = this.bons.filter(b => code == b.group_id)
					if(details_code.length > group[code]) {
						this.message_partiel = true
						break;
					}
				}
			},
			cleanHTML(input) {
				return xss(input)
			},
			async checkDateCloture() {
				const details_selected = this.bons.filter(bons => this.invoicedetails_ids_selected.includes(bons.invoicedetails_id))
				const details_author = _uniq(details_selected.map(details => details.author.tiers_id))
				this.accounting_closed = false
				for(let i in details_author) {
					let accouting_plan = await this.loadAccountingPlanByEntity(details_author)
					if(accouting_plan.accountingplan_closing_date < this.date) {
						this.accounting_closed = accouting_plan.accountingplan_id
						break
					}
				}
			}
		},
		computed: {
			duedates: function(){
				return this.params_to_send.invoices
			}
		},
		watch: {
			date(val, prev)
			{
				this.date.setHours(0)
				this.date.setMinutes(0)
				this.date.setSeconds(0)
				if(this.recalcul_duedate)
				{
					this.add_duedate()
					this.reset_copy_duedate()
				}
				this.calculDuedateDate()
				this.checkDateCloture()
			},
			'params_to_send.invoices':{
				handler(val){

					if(JSON.stringify(val).indexOf('duedates') > -1 && this.copy_duedate == null && this.recalcul_duedate) {
						this.copy_duedate = JSON.stringify(val)
					}

					if(this.copy_duedate != null) {
						if(JSON.stringify(val) != this.copy_duedate) {
							//Je passe une variable pour ne pas que cela recalcul les dates d'échéances 
							this.recalcul_duedate = false
						}
					}

					if(!this.recalcul_duedate) {
						let copy_duedate = JSON.parse(this.copy_duedate)
						for (let i = 0; i < val.length; i++) {
							if(val[i].rib && copy_duedate[i].rib && val[i].rib.checkdepositconfiguration_id != copy_duedate[i].rib.checkdepositconfiguration_id) {
								this.recalcul_duedate = true
								break;
							}
							if(val[i].rib && !copy_duedate[i].rib) {
								this.recalcul_duedate = true
								break;
							}
						}
					}
				},
				deep: true
			},
			many_duedate(val, prev){

				if(val === false) {
					this.add_duedate()
					this.reset_copy_duedate()
				}
			},
			change_model(val, prev){
				if(val === true) {
					this.load_model()
				}
				else{
					this.change_model_ready = false
				}

			},
			send_email(val, prev){
				if(val === true) {
					this.send_email_ready = true
					this.load_model_notification()
				}
				else{
					this.send_email_ready = false
				}
			},
			change_rib(val, prev){
				if(val === true) {
					this.load_rib()
				}
				else{
					this.change_rib_ready = false
				}
			},
			today() {
				this.today.setHours(0)
				this.today.setMinutes(0)
				this.today.setSeconds(0)
			}
		},
		components: {
			CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
			LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
			ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
			WarnAlert : () => import('GroomyRoot/components/Alert/WarnAlert'),
			LigneVierge: () => import('@/components/Invoice/LigneVierge'),
			Reorganize: () => import('@/components/Invoice/Reorganize'),
			ModalSelectModelPreviewBAF: () => import('@/components/Invoice/ModalSelectModelPreviewBAF'),
			ModalAvoirCC: () => import('@/components/Invoice/ModalAvoirCC')
		}
	}
</script>
